// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-thank-you-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/ThankYou.jsx" /* webpackChunkName: "component---src-templates-thank-you-jsx" */),
  "component---src-templates-glossary-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/glossary.jsx" /* webpackChunkName: "component---src-templates-glossary-jsx" */),
  "component---src-templates-testimonials-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/Testimonials.jsx" /* webpackChunkName: "component---src-templates-testimonials-jsx" */),
  "component---src-templates-current-opening-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/CurrentOpening.jsx" /* webpackChunkName: "component---src-templates-current-opening-jsx" */),
  "component---src-templates-claimproposal-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/claimproposal.jsx" /* webpackChunkName: "component---src-templates-claimproposal-jsx" */),
  "component---src-templates-covid-score-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/CovidScore.jsx" /* webpackChunkName: "component---src-templates-covid-score-jsx" */),
  "component---src-templates-about-us-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/AboutUs.jsx" /* webpackChunkName: "component---src-templates-about-us-jsx" */),
  "component---src-templates-with-drawn-products-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/WithDrawnProducts.jsx" /* webpackChunkName: "component---src-templates-with-drawn-products-jsx" */),
  "component---src-templates-free-look-period-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/freeLookPeriod.jsx" /* webpackChunkName: "component---src-templates-free-look-period-jsx" */),
  "component---src-templates-tax-benefits-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/taxBenefits.jsx" /* webpackChunkName: "component---src-templates-tax-benefits-jsx" */),
  "component---src-templates-disclaimer-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/Disclaimer.jsx" /* webpackChunkName: "component---src-templates-disclaimer-jsx" */),
  "component---src-templates-safebuying-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/safebuying.jsx" /* webpackChunkName: "component---src-templates-safebuying-jsx" */),
  "component---src-templates-covid-19-faq-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/covid19faq.jsx" /* webpackChunkName: "component---src-templates-covid-19-faq-jsx" */),
  "component---src-templates-regulations-on-appointment-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/regulationsOnAppointment.jsx" /* webpackChunkName: "component---src-templates-regulations-on-appointment-jsx" */),
  "component---src-templates-antifraud-policy-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/antifraudPolicy.jsx" /* webpackChunkName: "component---src-templates-antifraud-policy-jsx" */),
  "component---src-templates-media-center-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/mediaCenter.jsx" /* webpackChunkName: "component---src-templates-media-center-jsx" */),
  "component---src-templates-network-hospitals-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/NetworkHospitals.jsx" /* webpackChunkName: "component---src-templates-network-hospitals-jsx" */),
  "component---src-templates-list-agents-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/ListAgents.jsx" /* webpackChunkName: "component---src-templates-list-agents-jsx" */),
  "component---src-templates-downloads-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/Downloads.jsx" /* webpackChunkName: "component---src-templates-downloads-jsx" */),
  "component---src-templates-locate-us-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/locateUs.jsx" /* webpackChunkName: "component---src-templates-locate-us-jsx" */),
  "component---src-templates-partners-corner-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/PartnersCorner.jsx" /* webpackChunkName: "component---src-templates-partners-corner-jsx" */),
  "component---src-templates-dnd-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/DND.jsx" /* webpackChunkName: "component---src-templates-dnd-jsx" */),
  "component---src-templates-diabetes-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/Diabetes.jsx" /* webpackChunkName: "component---src-templates-diabetes-jsx" */),
  "component---src-templates-sitemap-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/sitemap.jsx" /* webpackChunkName: "component---src-templates-sitemap-jsx" */),
  "component---src-templates-grievance-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/Grievance.jsx" /* webpackChunkName: "component---src-templates-grievance-jsx" */),
  "component---src-templates-mobile-all-policies-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/MobileAllPolicies.jsx" /* webpackChunkName: "component---src-templates-mobile-all-policies-jsx" */),
  "component---src-templates-portability-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/portability.jsx" /* webpackChunkName: "component---src-templates-portability-jsx" */),
  "component---src-templates-fa-qs-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/FAQs.jsx" /* webpackChunkName: "component---src-templates-fa-qs-jsx" */),
  "component---src-templates-list-products-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/ListProducts.jsx" /* webpackChunkName: "component---src-templates-list-products-jsx" */),
  "component---src-templates-preventive-health-checkup-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/PreventiveHealthCheckup.jsx" /* webpackChunkName: "component---src-templates-preventive-health-checkup-jsx" */),
  "component---src-templates-gmc-all-policies-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/GmcAllPolicies.jsx" /* webpackChunkName: "component---src-templates-gmc-all-policies-jsx" */),
  "component---src-templates-check-eligibility-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/CheckEligibility.jsx" /* webpackChunkName: "component---src-templates-check-eligibility-jsx" */),
  "component---src-templates-instant-buy-revamp-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/InstantBuyRevamp.jsx" /* webpackChunkName: "component---src-templates-instant-buy-revamp-jsx" */),
  "component---src-templates-buy-health-insurance-online-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/BuyHealthInsuranceOnline.jsx" /* webpackChunkName: "component---src-templates-buy-health-insurance-online-jsx" */),
  "component---src-templates-covid-press-release-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/CovidPressRelease.jsx" /* webpackChunkName: "component---src-templates-covid-press-release-jsx" */),
  "component---src-templates-talk-to-doctor-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/TalkToDoctor.jsx" /* webpackChunkName: "component---src-templates-talk-to-doctor-jsx" */),
  "component---src-templates-as-check-eligibility-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/ASCheckEligibility.jsx" /* webpackChunkName: "component---src-templates-as-check-eligibility-jsx" */),
  "component---src-templates-installment-products-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/InstallmentProducts.jsx" /* webpackChunkName: "component---src-templates-installment-products-jsx" */),
  "component---src-templates-complete-health-insurance-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/CompleteHealthInsurance.jsx" /* webpackChunkName: "component---src-templates-complete-health-insurance-jsx" */),
  "component---src-templates-buy-complete-health-insurance-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/BuyCompleteHealthInsurance.jsx" /* webpackChunkName: "component---src-templates-buy-complete-health-insurance-jsx" */),
  "component---src-templates-allpolicies-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/allpolicies.jsx" /* webpackChunkName: "component---src-templates-allpolicies-jsx" */),
  "component---src-templates-form-release-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/FormRelease.jsx" /* webpackChunkName: "component---src-templates-form-release-jsx" */),
  "component---src-templates-static-privacy-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/StaticPrivacy.jsx" /* webpackChunkName: "component---src-templates-static-privacy-jsx" */),
  "component---src-templates-free-health-insurance-quote-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/FreeHealthInsuranceQuote.jsx" /* webpackChunkName: "component---src-templates-free-health-insurance-quote-jsx" */),
  "component---src-templates-underwriting-philosophy-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/UnderwritingPhilosophy.jsx" /* webpackChunkName: "component---src-templates-underwriting-philosophy-jsx" */),
  "component---src-templates-family-health-optima-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/FamilyHealthOptima.jsx" /* webpackChunkName: "component---src-templates-family-health-optima-jsx" */),
  "component---src-templates-retail-toi-cardiac-care-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/retail/ToiCardiacCare.jsx" /* webpackChunkName: "component---src-templates-retail-toi-cardiac-care-jsx" */),
  "component---src-templates-retail-google-senior-citizen-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/retail/GoogleSeniorCitizen.jsx" /* webpackChunkName: "component---src-templates-retail-google-senior-citizen-jsx" */),
  "component---src-templates-parents-health-insurance-products-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/ParentsHealthInsuranceProducts.jsx" /* webpackChunkName: "component---src-templates-parents-health-insurance-products-jsx" */),
  "component---src-templates-maternity-health-insurance-products-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/MaternityHealthInsuranceProducts.jsx" /* webpackChunkName: "component---src-templates-maternity-health-insurance-products-jsx" */),
  "component---src-templates-individual-health-insurance-products-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/IndividualHealthInsuranceProducts.jsx" /* webpackChunkName: "component---src-templates-individual-health-insurance-products-jsx" */),
  "component---src-templates-health-insurance-plans-for-family-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/HealthInsurancePlansForFamily.jsx" /* webpackChunkName: "component---src-templates-health-insurance-plans-for-family-jsx" */),
  "component---src-pages-portability-plans-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/pages/PortabilityPlans.jsx" /* webpackChunkName: "component---src-pages-portability-plans-jsx" */),
  "component---src-templates-plan-list-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/PlanList.jsx" /* webpackChunkName: "component---src-templates-plan-list-jsx" */),
  "component---src-templates-claim-list-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/ClaimList.jsx" /* webpackChunkName: "component---src-templates-claim-list-jsx" */),
  "component---src-templates-contact-us-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/ContactUs.jsx" /* webpackChunkName: "component---src-templates-contact-us-jsx" */),
  "component---src-templates-wellnessand-teli-medicine-service-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/wellnessandTeliMedicineService.jsx" /* webpackChunkName: "component---src-templates-wellnessand-teli-medicine-service-jsx" */),
  "component---src-templates-phc-swalkin-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/PHCSwalkin.jsx" /* webpackChunkName: "component---src-templates-phc-swalkin-jsx" */),
  "component---src-templates-phc-sdoorstep-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/PHCSdoorstep.jsx" /* webpackChunkName: "component---src-templates-phc-sdoorstep-jsx" */),
  "component---src-templates-phc-service-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/PHCService.jsx" /* webpackChunkName: "component---src-templates-phc-service-jsx" */),
  "component---src-templates-plan-details-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/PlanDetails.jsx" /* webpackChunkName: "component---src-templates-plan-details-jsx" */),
  "component---src-templates-health-checkup-packages-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/HealthCheckupPackages.jsx" /* webpackChunkName: "component---src-templates-health-checkup-packages-jsx" */),
  "component---src-templates-finance-by-quarter-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/templates/financeByQuarter.jsx" /* webpackChunkName: "component---src-templates-finance-by-quarter-jsx" */),
  "component---src-pages-404-js": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-maintanence-page-jsx": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/pages/MaintanencePage.jsx" /* webpackChunkName: "component---src-pages-maintanence-page-jsx" */),
  "component---src-pages-page-2-js": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-thankyou-js": () => import("/var/lib/jenkins/workspace/SH_Website/Gatsby_Workspace/src/pages/Thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */)
}

