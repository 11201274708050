var encObj = require(`crypto-js`);
const userObjEnc = `ewfWE@#%$rfdsefgdsf`;

export const isBrowser = typeof window !== "undefined";
export const browserWindow = isBrowser
  ? window
  : {
      pageYOffset: 0,
      location: { pathname: "", href: "", search: "", hash: "" },
      innerWidth: 0,
      addEventListener: () => console.log(`window addEventListener`),
      removeEventListener: () => console.log(`window removeEventListener`),
      navigator: { userAgent: "" },
      grecaptcha: { render: () => console.log(`window grecaptacha render`) },
      getComputedStyle: () => ({
        getPropertyValue: () =>
          console.log(`window getComputedStyle getPropertyValue`),
      }),
      LC_API: {
        on_chat_window_minimized: () =>
          console.log(`window LC_API on_chat_window_minimized`),
        open_chat_window: () => console.log(`window LC_API open_chat_window`),
      },
      open: () => console.log(`window open`),
      atob: () => console.log(`window atob`),
      scrollTo: () => console.log(`window scrollTO`),
      hjSiteSettings: false,
      innerHeight: 0,
    };
export const browserDocument = isBrowser
  ? document
  : {
      querySelector: () => console.log(`document querySelector`),
      body: {
        appendChild: () => console.log(`document body appendChild`),
        getElementsByTagName: () =>
          console.log(`document body getElementsByTagName`),
        scrollTop: 0,
      },
      head: {
        appendChild: () => console.log(`document head appendChild`),
      },
      getElementById: () => ({
        setAttribute: () => console.log(`document getElementById setAttribute`),
        removeAttribute: () =>
          console.log(`document getElementById removeAttribute`),
        scrollIntoView: () =>
          console.log(`document getElementById scrollIntoView`),
        getElementsByTagName: () =>
          console.log(`document getElementById getElementsByTagName`),
        offsetTop: 0,
      }),
      readyState: "",
      getElementsByClassName: () =>
        console.log(`document getElementsByClassName`),
      getElementsByTagName: () => {
        console.log(`document getElementsByTagName`);
        return [];
      },
      createElement: () => {
        console.log(`document createElement`);
        return {};
      },
      addEventListener: () => console.log(`document addEventListener`),
      documentElement: {},
    };
export const browserLocalStorage = isBrowser
  ? localStorage
  : {
      setItem: () => console.log(`localStorage setItem`),
      getItem: () => {
        console.log(`localStorage getItem`);
        return JSON.stringify({ value: "" });
      },
      removeItem: () => console.log(`localStorage removeItem`),
    };

export function getSecuredLocalStorageItem(key) {
  if (isBrowser) {
    if (key === `user`) {
      let val = browserLocalStorage.getItem(key);
      if (val) {
        try {
          let dval = encObj.AES.decrypt(val, userObjEnc);
          return dval.toString(encObj.enc.Utf8);
        } catch {
          return val;
        }
      }
      return null;
    }
    return browserLocalStorage.getItem(key);
  } else {
    return "";
  }
}
