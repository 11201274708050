/* eslint-disable react/no-danger */

import React from 'react';
import ReactDOM from 'react-dom'

import Root from './src/root';

export function wrapPageElement({ element, props }) {
  return element;
}

export function wrapRootElement({ element }) {
  return <Root>{element}</Root>;
}

export function replaceHydrateFunction() {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback)
  }
}